import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueMobileDetection from 'vue-mobile-detection'
import { loadFonts } from "./plugins/webfontloader";
loadFonts();

import TuiGrid from "vue3-tui-grid";
import "tui-grid/dist/tui-grid.css";
import "tui-date-picker/dist/tui-date-picker.css"; // use datepicker

import 'v3-easyui/dist/themes/material/easyui.css';
import 'v3-easyui/dist/themes/icon.css';
import 'v3-easyui/dist/themes/vue.css';
import EasyUI from 'v3-easyui';

createApp(App)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(TuiGrid)
  .use(EasyUI)
  .use(VueMobileDetection)
  .mount("#app");
