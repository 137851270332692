import { createRouter, createWebHistory } from "vue-router";
import { check } from "@/@core/api/Auth";

import iot_user from "@iot_user/router";
import iot_admin from "@iot_admin/router";
import core from "@core/router";

const redirect_home_router = "UserRouter";
const routes = [
  {
    path: "/",
    redirect: { name: redirect_home_router },
  },
  iot_user,
  iot_admin,
  core,
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await check();
  next();
});

export default router;
