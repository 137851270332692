import RouterSection from "@core/layout/RouterSection";

export default {
  path: "/core",
  name: "CoreRouter",
  component: RouterSection,
  redirect: { name: "CoreLogin" },
  children: [
    {
      path: "/core/login",
      name: "CoreLogin",
      component: () =>
        import(
          /* webpackChunkName: "CoreLogin" */ "@core/views/login/CoreLogin.vue"
        ),
    },
    {
      path: "/core/logout",
      name: "CoreLogout",
      component: () =>
        import(
          /* webpackChunkName: "CoreLogout" */ "@core/views/logout/CoreLogout.vue"
        ),
    },
    {
      path: "/core/sso",
      name: "CoreSSO",
      component: () =>
        import(
          /* webpackChunkName: "CoreSSO" */ "@core/views/sso/CoreSSO.vue"
        ),
    },
  ],
  beforeEnter: async (to, from, next) => {
    next();
  },
};
