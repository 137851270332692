<template>
  <v-app>
    <app-header></app-header>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import AppHeader from "@core/layout/AppHeader";
export default {
  name: "App",
  components: { AppHeader },
  data: () => ({
    //
  }),
};
</script>

<style>
@import url("https://hangeul.pstatic.net/hangeul_static/css/nanum-gothic-coding.css");
@import url("https://hangeul.pstatic.net/hangeul_static/css/nanum-barun-gothic.css");
* {
  font-family: "NanumBarunGothic";
}
</style>
