<template>
  <router-view v-slot="{ Component }" :key="key">
    <v-fade-transition leave-absolute>
      <component :is="Component" />
    </v-fade-transition>
  </router-view>
</template>

<script>
export default {
  name: "RouterSection",
  beforeUpdate() {},
  updated() {},
  mounted() {},
  methods: {},
  computed: {
    key() {
      return this.$route.fullPath;
    },
  },
  data() {
    return {};
  },
};
</script>
