import RouterSection from "@iot_user/layout/RouterSection";
import store from "@/store";

export default {
  path: "/user",
  name: "UserRouter",
  component: RouterSection,
  redirect: { name: "UserDashboard" },
  children: [
    {
      path: "/user/dashboard",
      name: "UserDashboard",
      component: () =>
        import(
          /* webpackChunkName: "DashBoard" */ "@iot_user/views/home/DashBoard.vue"
        ),
    },
    {
      path: "/user/space",
      name: "MySpace",
      component: () =>
        import(
          /* webpackChunkName: "MySpace" */ "@iot_user/views/home/MySpace.vue"
        ),
    },
    {
      path: "/user/space/:spaceUUID",
      name: "MySpaceInfo",
      props: true,
      component: () =>
        import(
          /* webpackChunkName: "MySpaceInfo" */ "@iot_user/views/home/MySpaceInfo.vue"
        ),
    },
  ],
  beforeEnter: async (to, from, next) => {
    const userInfo = store.getters["core/getUserInfo"];
    if (userInfo == null) {
      alert("로그인 후 이용 가능합니다.");
      next({ path: "/core" });
    } else {
      next();
    }
  },
};
