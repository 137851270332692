import { createStore } from "vuex";
import core from "@core/store";
import iot_admin from "@iot_admin/store";
import iot_user from "@iot_user/store";
export default createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    core,
    iot_admin,
    iot_user,
  },
});
