import { requests } from "@utils/requests";
export const doLogin = async (userId, userPassword) => {
  const data = await requests({
    url: "/api/auth/login",
    method: "POST",
    data: {
      userId,
      userPassword,
    },
  });
  return data;
};
export const doSSO = async (token) => {
  const data = await requests({
    url: "/api/auth/sso",
    method: "POST",
    data: {
      token,
    },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return data;
};

export const check = async () => {
  const data = await requests({
    url: "/api/auth/check",
    method: "GET",
  });
  return data;
};

export const doLogout = async () => {
  const data = await requests({
    url: "/api/auth/logout",
    method: "GET",
  });
  return data;
};
