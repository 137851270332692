import axios from "axios";
import store from "@/store";

const getFileName = (contentDescription) => {
  const regex =
    /(?<=filename(?:=|\*=(?:[\w-]+'')))["']?(?<filename>[^"';\n]+)["']?/g;
  // const regex = /filename=["'](?<filename>.*)["']/g;
  const matches = contentDescription.matchAll(regex);
  const groups = Array.from(matches).map((match) => match?.groups?.filename);
  console.log(groups);
  if (groups.length === 2) {
    return decodeURI(groups[1]);
  }
  if (groups.length === 1) {
    return groups[0];
  }
};

export const requests = async ({
  url,
  method,
  data,
  headers,
  options,
  extend,
}) => {
  // 추가 옵션값 설정
  const extOpt = { ...extend };
  extOpt.silence = extOpt.silence || false;
  const option = {
    url,
    method,
    data,
    headers,
    ...options,
  };
  const token = localStorage.getItem("loginToken");
  if (token != null && option.headers?.Authorization == undefined) {
    option.headers = { Authorization: `${token}` };
  }
  let res = await axios(option);
  let res_data = res.data;

  if (res.headers["content-type"] != null) {
    const contentType = res.headers["content-type"].split(";")[0].trim();
    if (contentType == "application/json") {
      // 데이터가 JSON 이면 JSON 검증을 통해 로그인 처리를 하고 메시징 처리후 데이터를 반환한다.
      /**
       * 1. 로그인 여부 확인후 로그인 정보 갱신
       * 2. 데이터 부분만 파싱해서 요청정보 간단하게 전송
       */
      if (res_data.authinfo?.auth?.isLogin) {
        store.commit("core/setUserInfo", res_data.authinfo?.auth?.userInfo);
      }
      return res_data.data;
    } else {
      // 데이터가 파일이면 다운로드 로직을 통해 파일을 다운로드 처리 한다.
      if (res.headers["content-disposition"] != null) {
        const contentDescription = res.headers["content-disposition"];
        const contentDescriptionList = contentDescription.split(";");
        const is_attachment = contentDescriptionList[0].trim();
        if (is_attachment == "attachment") {
          const fileName = getFileName(contentDescription);
          const url = window.URL.createObjectURL(
            new Blob([res.data], {
              type: res.headers["content-type"],
            })
          );
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `${fileName}`);
          link.style.cssText = "display:none";
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      }
    }
  }
  return res;
};

export const json2Query = (jsondata) => {
  return Object.entries(jsondata || {})
    .map(([key, value]) => value && key + "=" + value)
    .filter((v) => v)
    .join("&");
};
