<template>
  <v-navigation-drawer
    v-model="showNavigation"
    :app="!$isMobile()"
    :temporary="$isMobile()"
    theme="dark"
    v-if="isLogin"
  >
    <v-list-item height="48">
      <v-layout>
        <v-col class="px-0"><span>Service Menu</span></v-col>
      </v-layout>
    </v-list-item>

    <v-divider></v-divider>

    <v-list
      density="compact"
      nav
      @click:select="inputNavMenuList"
      v-model="selectedMenu"
    >
      <v-list-item
        prepend-icon="mdi-view-dashboard"
        title="Dashboard"
        value="/user/dashboard"
      ></v-list-item>
      <v-list-item
        prepend-icon="mdi-map-marker"
        title="My Space"
        value="/user/space"
      ></v-list-item>
      <v-list-group value="/manage/user" v-if="isAdmin">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-account-circle"
            title="사용자관리"
          ></v-list-item>
        </template>
        <v-list-item
          prepend-icon="mdi-account-group"
          title="사용자 그룹관리"
          value="/manage/user/group"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-table-account"
          title="사용자 등록관리"
          value="/manage/user/list"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-folder-account-outline"
          title="사용자 그룹할당"
          value="/manage/user/group/alloc"
        ></v-list-item>
      </v-list-group>
      <v-list-group value="/manage/device" v-if="isAdmin">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-cog-box"
            title="디바이스관리"
          ></v-list-item>
        </template>
        <v-list-item
          prepend-icon="mdi-folder-cog"
          title="디바이스 종류관리"
          value="/manage/device/group"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-table-cog"
          title="디바이스 등록관리"
          value="/manage/device/list"
        ></v-list-item>
      </v-list-group>
      <v-list-group value="/manage/space" v-if="isAdmin">
        <template v-slot:activator="{ props }">
          <v-list-item
            v-bind="props"
            prepend-icon="mdi-map-marker-plus"
            title="스페이스관리"
          ></v-list-item>
        </template>
        <v-list-item
          prepend-icon="mdi-map-marker-star-outline"
          title="스페이스 등록관리"
          value="/manage/space/list"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-table-account"
          title="스페이스 권한할당"
          value="/manage/space/alloc/user"
        ></v-list-item>
        <v-list-item
          prepend-icon="mdi-table-cog"
          title="스페이스 장치할당"
          value="/manage/space/alloc/device"
        ></v-list-item>
      </v-list-group>
    </v-list>
  </v-navigation-drawer>
  <v-app-bar color="black" theme="dark" density="compact" fixed elevation="0">
    <v-app-bar-nav-icon
      variant="text"
      @click.stop="showNavigation = !showNavigation"
      v-if="isLogin"
    ></v-app-bar-nav-icon>
    <v-app-bar-title> IoT Portal For Intellyscent </v-app-bar-title>
    <v-spacer></v-spacer>
    <v-menu transition="scale-transition" v-if="isLogin">
      <template v-slot:activator="{ props }">
        <v-btn variant="text" icon="mdi-account" v-bind="props"></v-btn>
      </template>
      <v-list density="compact">
        <!-- <v-list-item title="내정보" value="/user/myinfo"> </v-list-item> -->
        <v-list-item title="로그아웃" value="logout" @click="goLogout">
        </v-list-item>
      </v-list>
    </v-menu>
  </v-app-bar>
</template>
<script>
import store from "@/store";
export default {
  methods: {
    inputNavMenuList(value) {
      if (value.id != this.$router.currentRoute.path) {
        this.$router.push(value.id);
      }
    },
    goLogout() {
      this.$router.push("/core/logout");
    },
  },
  computed: {
    isLogin() {
      const userInfo = store.getters["core/getUserInfo"];
      return userInfo != null;
    },
    isAdmin(){
      if(!this.isLogin) return false;
      const userInfo = store.getters["core/getUserInfo"];
      const groups = userInfo.group || [];
      for(const group of groups){
        if(group?.TB_USER_GROUP_LIST?.userGroupIsAdmin == true){
          return true
        }
      }
      return false;
    }
  },
  data() {
    return {
      showNavigation: true,
      selectedMenu: "/user/dashboard",
    };
  },
};
</script>
