// Styles
import "@mdi/font/css/materialdesignicons.css";
import '@/styles/main.scss'

// Vuetify
import { createVuetify } from "vuetify";

export default createVuetify(
  {
    display: {
      mobileBreakpoint: "sm",
      thresholds: {
        xs: 0,
        sm: 340,
        md: 540,
        lg: 800,
        xl: 1280,
      },
    },
    theme: {
      defaultTheme: "light",
    },
  }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
);
