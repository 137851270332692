import RouterSection from "@iot_admin/layout/RouterSection";
import store from "@/store";

export default {
  path: "/manager",
  name: "AdminRouter",
  component: RouterSection,
  redirect: { name: "ManageUserList" },
  children: [
    {
      path: "/manage/user/list",
      name: "ManageUserList",
      component: () =>
        import(
          /* webpackChunkName: "UserAccount" */ "@iot_admin/views/user/UserAccount.vue"
        ),
    },
    {
      path: "/manage/user/group",
      name: "ManageUserGroup",
      component: () =>
        import(
          /* webpackChunkName: "UserGroup" */ "@iot_admin/views/user/UserGroup.vue"
        ),
    },
    {
      path: "/manage/user/group/alloc",
      name: "ManageUserGroupAlloc",
      component: () =>
        import(
          /* webpackChunkName: "UserGroupAlloc" */ "@iot_admin/views/user/UserGroupAlloc.vue"
        ),
    },
    {
      path: "/manage/device/group",
      name: "ManageDeviceGroup",
      component: () =>
        import(
          /* webpackChunkName: "DeviceGroup" */ "@iot_admin/views/device/DeviceGroup.vue"
        ),
    },
    {
      path: "/manage/device/list",
      name: "ManageDeviceList",
      component: () =>
        import(
          /* webpackChunkName: "DeviceList" */ "@iot_admin/views/device/DeviceList.vue"
        ),
    },

    {
      path: "/manage/space/list",
      name: "ManageSpaceList",
      component: () =>
        import(
          /* webpackChunkName: "SpaceList" */ "@iot_admin/views/space/SpaceList.vue"
        ),
    },
    {
      path: "/manage/space/alloc/user",
      name: "SpaceUserAlloc",
      component: () =>
        import(
          /* webpackChunkName: "SpaceUserAlloc" */ "@iot_admin/views/space/SpaceUserAlloc.vue"
        ),
    },
    {
      path: "/manage/space/alloc/device",
      name: "SpaceDeviceAlloc",
      component: () =>
        import(
          /* webpackChunkName: "SpaceDeviceAlloc" */ "@iot_admin/views/space/SpaceDeviceAlloc.vue"
        ),
    },
  ],
  beforeEnter: async (to, from, next) => {
    const userInfo = store.getters["core/getUserInfo"];
    if (userInfo == null) {
      alert("로그인 후 이용 가능합니다.");
      next({ path: "/core" });
    } else {
      next();
    }
  },
};
